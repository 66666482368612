import { render, staticRenderFns } from "./Realtime.vue?vue&type=template&id=38ddee6a&scoped=true&"
import script from "./Realtime.vue?vue&type=script&lang=js&"
export * from "./Realtime.vue?vue&type=script&lang=js&"
import style0 from "../../node_modules/vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./Realtime.vue?vue&type=style&index=1&id=38ddee6a&prod&lang=scss&scoped=true&"
import style2 from "./Realtime.vue?vue&type=style&index=2&id=38ddee6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ddee6a",
  null
  
)

export default component.exports